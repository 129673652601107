/* You can add global styles to this file, and also import other style files */
:root {
  --primary: rgb(55, 144, 173);
  --secondary-blue: rgb(115, 149, 167);
  --yellow: rgb(232, 188, 44);
  --yellow-light: rgb(230, 222, 168);
}

html,
body {
  height: 100%;
  background-color: rgba(221, 221, 221, 0.1);
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1.15rem;
}

.page {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

.mat-flat-button.mat-primary {
  background-color: var(--primary);
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--primary);
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.sm\:flex-row {
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.items-center {
  align-items: center;
}

.p-0 {
  padding: 0 !important;
}

.max-w-full {
  max-width: 100%;
}

.w-80 {
  width: (80rem/4);
}

.w-72 {
  width: (72rem/4);
}

.p-4 {
  padding: 1rem;
}

.mb-4 {
  padding-bottom: 1rem;
}

.mb-3 {
  padding-bottom: 0.75rem;
}

.scale-150 {
  transform: scale(1.5);
}

.text-red-400 {
  color: rgb(200, 0, 0);
}

.text-green-400 {
  color: rgb(0, 200, 0);
}

.inline-block {
  display: inline-block !important;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.mt-0 {
  margin-top: 0;
}
